import { render, staticRenderFns } from "./messages.vue?vue&type=template&id=a9f03cc4&scoped=true&"
import script from "./messages.vue?vue&type=script&lang=js&"
export * from "./messages.vue?vue&type=script&lang=js&"
import style0 from "./messages.vue?vue&type=style&index=0&id=a9f03cc4&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a9f03cc4",
  null
  
)

export default component.exports
import {QCard,QCardSection,QInnerLoading,QSpinnerBars,QTable,QSelect,QAvatar,QTr,QTh,QTd,QSpinnerComment,QImg,QTooltip} from 'quasar'
component.options.components = Object.assign(Object.create(component.options.components || null), component.options.components || {}, {QCard,QCardSection,QInnerLoading,QSpinnerBars,QTable,QSelect,QAvatar,QTr,QTh,QTd,QSpinnerComment,QImg,QTooltip})
