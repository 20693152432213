//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters, mapActions } from "vuex";
import eventBus from "@/plugins/eventBus";
import * as _ from "underscore";
import XLSX from 'xlsx'

const momentTz = require('moment-timezone');

export default {
  name: "AIMessages",
  data() {
    return {
      showInnerLoading: false,
      table_data: [],
      triggered_Intents: [],
      current_intent: "",
      showSessionMessages: false,
      currentSession: {},
      showMessageLoading: false,
      columns: [
        {
          name: "user_content",
          required: true,
          label: "User messages",
          align: "left",
          field: (row) => row.user_content,
          sortable: true,
        },
        {
          name: "created_time",
          required: true,
          label: "Date and Time",
          align: "left",
          field: (row) => row.created_time,
          sortable: true,
        },
        {
          name: "rating",
          required: true,
          label: "Confidence Score",
          align: "left",
          field: (row) => row.rating,
          sortable: true,
        },
        {
          name: "reviewStatus",
          required: true,
          label: "Conversation",
          align: "left",
          field: (row) => row.reviewStatus,
          sortable: true,
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      getFilterDate: "getFilterDate",
      currentAIAccount: "getCurrentAIAccount",
      currentUser: "getCurrentUser",
      darkTheme: "getDarkTheme",
    }),
    current_intent_list() {
      if(this.current_intent === 'All'){
        return this.table_data
      }else{
        return _.filter(this.table_data, (record) => {
          return record.mainParameter === this.current_intent;
        });
      }
    },
  },
  methods: {
    ...mapActions({
      requestAIMessages: "requestAIMessages",
      requestSpecificSession: "requestSpecificSession",
    }),
    updateMessagesTable(data) {
      this.showInnerLoading = true;
      let routeQuery = this.$route.query
      this.requestAIMessages(data).then((resp) => {
          this.table_data = resp.result;
          this.triggered_Intents = _.uniq(
            _.pluck(resp.result, "mainParameter")
          );
          this.triggered_Intents.unshift('All')
          if(_.has(routeQuery , 'intent')){
            if(_.contains(this.triggered_Intents , routeQuery.intent)){
              this.current_intent = routeQuery.intent;
            }else{
              this.current_intent = this.triggered_Intents[0];
            }
          }else{
            this.current_intent = this.triggered_Intents[0];
          }
        }).catch((err) => {
          console.log(err)
          this.$notify({
            group: "notifications",
            type: "error",
            title: "Action Error",
            text: err.errmsg,
          });
        })
        .finally(() => {
          this.showInnerLoading = false;
        });
    },
    viewSessionInfo(session) {
      const that = this;
      const data = {
        sessionId: session.session_id,
        projectId: that.currentAIAccount.id,
      };
      that.currentSession.id = session.session_id;
      that.showSessionMessages = true;
      that.showMessageLoading = true;
      that.requestSpecificSession(data).then((res) => {
        res.start_time = momentTz.tz(res.start_time, res.timeZone).format('DD MMM YYYY hh:mm:ss a');
          that.updateCurrentSession(res);
        }).catch((err) => {
          console.log("err ", err);
          this.$notify({
            group: "notifications",
            type: "error",
            title: "Action Error",
            text: err.errmsg,
          });
        });
    },
    updateCurrentSession(chatObj) {
      const that = this;
      _.map(chatObj.messages, (message) => {
        if (message.custom_info !== undefined) {
          try {
            message.custom_info_parsed = JSON.parse(message.custom_info);
          } catch (e) {
            console.log({ error: e, data: message });
            message.custom_info_parsed = {};
          }
        } else {
          message.custom_info_parsed = {};
        }
        if (message.analysis && message.analysis.length > 0) {
          message.custom_infoA = JSON.parse(message.analysis[0].custom_info);
          message.mainParameter = message.analysis[0].mainParameter;
          message.confidenceScore = _.pluck(
            message.analysis,
            "rating"
          )[0].toFixed(2);
          const { parametersParsed } = message.custom_infoA;
          if (parametersParsed !== undefined) {
            message.parametersParsed = _.values(parametersParsed);
            message.showParameters = true;
          } else {
            message.showParameters = false;
          }
          message.analysis = that.jsonParser(message.analysis);
          _.map(message.analysis, (analys) => {
            analys.custom_infoA = that.jsonParser(analys.custom_info);
            if (analys.msgtype == "text" || analys.msgtype == "SCAN" ||analys.msgtype == "subscribe" ) {
              analys.contentArr = [];
              const _isFULFILLMENTMSG = analys.content.includes("{FULFILLMENTMSGDELIMITER}");
              if (_isFULFILLMENTMSG == true) {
                const _FULFILLMENTMSGARRAY = analys.content.split("{FULFILLMENTMSGDELIMITER}");
                analys.contentArr = _FULFILLMENTMSGARRAY;
              } else {
                analys.contentArr[0] = analys.content;
              }
            } else if (analys.msgtype == "card") {
              const mediaMsgArr = [];
              const parsed_content = JSON.parse(analys.content);
              for (const key in parsed_content) {
                _.forEach(parsed_content[key], (mediaMsg) => {
                  mediaMsgArr.push(mediaMsg);
                });
              }
              analys.mediaMsgArr = mediaMsgArr;
            } else if (analys.msgtype == "image") {
              analys.parsed_content = JSON.parse(analys.content);
            } else if (analys.msgtype == "quick") {
              analys.parsed_content = JSON.parse(analys.content);
              const quickReplyArr = [];
              const parsed_content = JSON.parse(analys.content);
              for (const key in parsed_content) {
                _.forEach(parsed_content[key], (reply) => {
                  quickReplyArr.push(reply);
                });
              }
              analys.quickReplyArr = quickReplyArr;
            } else if (analys.msgtype == "multi") {
              return that.parseMultiMessages(analys);
            }
            return analys;
          });
        }
        if (!message.reviewStatus) {
          message.reviewStatus = "unflagged";
        }
        return message;
      });
      that.currentSession = chatObj;
      that.showMessageLoading = false;
    },
    parseMultiMessages(analys) {
      const that = this;
      analys.parsed_content = that.jsonParser(analys.content);
      if (Array.isArray(analys.parsed_content)) {
        _.forEach(analys.parsed_content, (multiMessage, mindex) => {
          if (multiMessage.msgtype == "text") {
            multiMessage.contentArr = [];
            if (multiMessage.content !== undefined) {
              const _isFULFILLMENTMSG = multiMessage.content.includes("{FULFILLMENTMSGDELIMITER}");
              if (_isFULFILLMENTMSG == true) {
                const _FULFILLMENTMSGARRAY = multiMessage.content.split("{FULFILLMENTMSGDELIMITER}");
                multiMessage.contentArr = _FULFILLMENTMSGARRAY;
              } else {
                multiMessage.contentArr[0] = multiMessage.content;
              }
            }
            return multiMessage;
          }
          if (multiMessage.msgtype == "card") {
            multiMessage.mediaMsgArr = that.jsonParser(multiMessage.content);
            return multiMessage;
          }
          if (multiMessage.msgtype == "image") {
            multiMessage.parsed_content = that.jsonParser(multiMessage.content);
            return multiMessage;
          }
          return multiMessage;
        });
      } else {
        console.log("not array  ", analys.content);
      }
      return analys;
    },
    jsonParser(obj) {
      try {
        return JSON.parse(obj);
      } catch (err) {
        return JSON.parse(JSON.stringify(obj));
      }
    },
    closeChat() {
      this.showSessionMessages = false;
      this.currentSession = {};
    },
    exportXls(){
      let fileName = `EVO_Dynamics_report${this.currentAIAccount.name}_${this.getFilterDate.start}_to_${this.getFilterDate.end}_message_report_intent_${this.current_intent}`
      var data = _.map(this.current_intent_list , (record , index) => {
        let messageInfo = {}
          messageInfo['S. No'] =  index + 1
          
          messageInfo['User Message'] =  record.user_content
          if(this.current_intent === 'All'){
          messageInfo['Intent'] =  record.mainParameter
          }
          messageInfo['Date & Time'] =  record.created_time
          messageInfo['Confidence Score'] =  record.rating
          messageInfo['Status'] =  record.reviewStatus
          return messageInfo
      });
      console.log(data)
      /* this line is only needed if you are not adding a script tag reference */
      /* make the worksheet */
      var ws = XLSX.utils.json_to_sheet(data);
      /* add to workbook */
      var wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "People");
      /* generate an XLSX file */
      XLSX.writeFile(wb, `${fileName}.xlsx`);
    },
    onDateUpdate(date) {
      const that = this;
      if (that.isValid(date.start) && that.isValid(date.end)) {
        that.pageNr = 1;
        that.pageSize = 10;
        const data = {
          aiAccount: that.currentAIAccount.id,
          startdate: date.start,
          enddate: date.end,
        };
        that.updateMessagesTable(data);
      } else {
        console.log("invalid date");
      }
    },
    onAccountUpdate(account) {
      const that = this;
      that.pageNr = 1;
      that.pageSize = 10;
      const data = {
        aiAccount: account.id,
        startdate: that.getFilterDate.start,
        enddate: that.getFilterDate.end,
      };
      that.updateMessagesTable(data);
    },
    isValid(value) {
      if (
        value === undefined ||
        value === null ||
        (typeof value === "object" && Object.keys(value).length === 0) ||
        (typeof value === "string" && value.trim().length === 0)
      ) {
        return false;
      }
      return true;
    },
  },
  watch: {},
  created() {
    eventBus.$on("updateDate", this.onDateUpdate);
    eventBus.$on("updateAIAccount", this.onAccountUpdate);
  },
  beforeDestroy() {
    const that = this;
    eventBus.$off("updateDate", this.onDateUpdate);
    eventBus.$off("updateAIAccount", this.onAccountUpdate);
  },
  destroyed() {
    eventBus.$off("updateDate", this.onDateUpdate);
    eventBus.$off("updateAIAccount", this.onAccountUpdate);
  },
  mounted() {
    const that = this;
    const data = {
      aiAccount: that.currentAIAccount.id,
      startdate: that.getFilterDate.start,
      enddate: that.getFilterDate.end,
    };
    that.updateMessagesTable(data);
  },
};
